/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./app.breadcrumb.component";
import * as i4 from "./breadcrumb.service";
var styles_AppBreadcrumbComponent = [];
var RenderType_AppBreadcrumbComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppBreadcrumbComponent, data: {} });
export { RenderType_AppBreadcrumbComponent as RenderType_AppBreadcrumbComponent };
function View_AppBreadcrumbComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_3); }); }
function View_AppBreadcrumbComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_AppBreadcrumbComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"]))], null, null); }
function View_AppBreadcrumbComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.routerLink; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.routerLink; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_AppBreadcrumbComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "route-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "route-bar-breadcrumb"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "a", [["routerLink", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["class", "fa fa-home"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["/"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppBreadcrumbComponent_1)), i0.ɵdid(9, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.items; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).target; var currVal_1 = i0.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_AppBreadcrumbComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-breadcrumb", [], null, null, null, View_AppBreadcrumbComponent_0, RenderType_AppBreadcrumbComponent)), i0.ɵdid(1, 180224, null, 0, i3.AppBreadcrumbComponent, [i4.BreadcrumbService], null, null)], null, null); }
var AppBreadcrumbComponentNgFactory = i0.ɵccf("app-breadcrumb", i3.AppBreadcrumbComponent, View_AppBreadcrumbComponent_Host_0, {}, {}, []);
export { AppBreadcrumbComponentNgFactory as AppBreadcrumbComponentNgFactory };
