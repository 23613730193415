import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginModel } from '@3mk/shared/models/login.model';
import { DatesRangeDTO } from '../dto/date-range.dto';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  logIn(payload: LoginModel) {
    return this.http.post(`${this.apiUrl}/auth/company/logIn`, payload);
  }

  getSalesPoints() {
    return this.http.get(`${this.apiUrl}/company/salesPoints`);
  }

  getSalesPointsSalesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-points/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSalesPointsErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/company/report/sales-points/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getSalesPointsExchangesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-points/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSalesPointsAssemblyErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-points/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesPointsSalesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-point/${id}/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesPointsErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-point/${id}/failed`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesPointsExchangesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-point/${id}/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesPointsAssemblyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/company/report/sales-point/${id}/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesPoint(id: string) {
    return this.http.get(`${this.apiUrl}/salesPoint/${id}/asCompany`);
  }
}
