
    <ul
      app-submenu
      [item]="model"
      root="true"
      class="layout-menu"
      [reset]="reset"
      visible="true"
      parentActive="true"
    ></ul>
  