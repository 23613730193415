import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    ApiService.prototype.logIn = function (payload) {
        return this.http.post(this.apiUrl + "/auth/company/logIn", payload);
    };
    ApiService.prototype.getSalesPoints = function () {
        return this.http.get(this.apiUrl + "/company/salesPoints");
    };
    ApiService.prototype.getSalesPointsSalesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-points/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesPointsErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-points/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesPointsExchangesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-points/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesPointsAssemblyErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-points/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesPointsSalesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-point/" + id + "/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesPointsErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-point/" + id + "/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesPointsExchangesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-point/" + id + "/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesPointsAssemblyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/company/report/sales-point/" + id + "/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesPoint = function (id) {
        return this.http.get(this.apiUrl + "/salesPoint/" + id + "/asCompany");
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
