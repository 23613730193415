import { Component } from '@angular/core';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  constructor(
    public app: AppComponent,
    private router: Router,
    private msgService: MessageService,
    private translate: TranslateService,
  ) {}

  logout() {
    localStorage.removeItem('auth');
    this.msgService.add({
      severity: 'success',
      summary: this.translate.instant('menu.logOutSuccess'),
    });
    setTimeout(() => this.router.navigateByUrl('/login'), 2000);
  }
}
