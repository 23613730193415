import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as translationPl from '../assets/i18n/pl.json';

const TRANSLATIONS = {
  pl: translationPl,
};

export class JSONModuleLoaderClient implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang].default);
  }
}
