<div class="layout-topbar">
  <a href="/" class="logo-container">
    <img
      class="logo"
      alt="harmony-layout"
      src="assets/layout/images/harmony-logo.png"
    />
    <span class="app-name">{{ 'menu.appName' | translate }}</span>
  </a>

  <div class="topbar-content">
    <div class="topbar-icons">
      <ul (click)="logout()">
        <li>
          <a>
            <i class="fa fa-power-off topbar-icon-settings"></i>
            <span class="icon-label">{{ 'menu.logOut' | translate }}</span>
          </a>
          <div class="layout-quickmenu-tooltip">
            <div class="layout-quickmenu-tooltip-arrow"></div>
            <div class="layout-quickmenu-tooltip-text">
              {{ 'menu.logOut' | translate }}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
