/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./app.topbar.component";
import * as i3 from "./app.component";
import * as i4 from "@angular/router";
import * as i5 from "primeng/components/common/messageservice";
var styles_AppTopBarComponent = [];
var RenderType_AppTopBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppTopBarComponent, data: {} });
export { RenderType_AppTopBarComponent as RenderType_AppTopBarComponent };
export function View_AppTopBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "div", [["class", "layout-topbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "logo-container"], ["href", "/"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "harmony-layout"], ["class", "logo"], ["src", "assets/layout/images/harmony-logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "app-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, null, null, 13, "div", [["class", "topbar-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 12, "div", [["class", "topbar-icons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 11, "ul", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 10, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 4, "a", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "fa fa-power-off topbar-icon-settings"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "span", [["class", "icon-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(15, 0, null, null, 4, "div", [["class", "layout-quickmenu-tooltip"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "div", [["class", "layout-quickmenu-tooltip-arrow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "layout-quickmenu-tooltip-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, [" ", " "])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("menu.appName")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform("menu.logOut")); _ck(_v, 13, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform("menu.logOut")); _ck(_v, 18, 0, currVal_2); }); }
export function View_AppTopBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-topbar", [], null, null, null, View_AppTopBarComponent_0, RenderType_AppTopBarComponent)), i0.ɵdid(1, 49152, null, 0, i2.AppTopBarComponent, [i3.AppComponent, i4.Router, i5.MessageService, i1.TranslateService], null, null)], null, null); }
var AppTopBarComponentNgFactory = i0.ɵccf("app-topbar", i2.AppTopBarComponent, View_AppTopBarComponent_Host_0, {}, {}, []);
export { AppTopBarComponentNgFactory as AppTopBarComponentNgFactory };
