/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./app.footer.component";
var styles_AppFooterComponent = [];
var RenderType_AppFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppFooterComponent, data: {} });
export { RenderType_AppFooterComponent as RenderType_AppFooterComponent };
export function View_AppFooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "layout-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [["class", "logo-container"], ["href", "/dashboard"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "harmony-layout"], ["src", "assets/layout/images/harmony-logo.png"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "app-name"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform("menu.appName")); _ck(_v, 4, 0, currVal_0); }); }
export function View_AppFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_AppFooterComponent_0, RenderType_AppFooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.AppFooterComponent, [], null, null)], null, null); }
var AppFooterComponentNgFactory = i0.ɵccf("app-footer", i2.AppFooterComponent, View_AppFooterComponent_Host_0, {}, {}, []);
export { AppFooterComponentNgFactory as AppFooterComponentNgFactory };
