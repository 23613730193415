<div class="loginPage login-body">
  <div class="logo">
    <img src="assets/layout/images/harmony-logo.png" alt="harmony-layout" />
    <span class="app-name">{{ 'menu.appName' | translate }}</span>
  </div>
  <div class="login-panel">
    <img
      src="assets/layout/images/template/image-login-mobile.jpg"
      alt="harmony-layout"
      class="mobile"
    />
    <form class="left-part" (ngSubmit)="onSubmit()" #formLogin="ngForm">
      <span class="login-panel-header">{{
        'logInPage.logIn' | translate
      }}</span>
      <span class="login-panel-subheader">{{
        'logInPage.welcome' | translate
      }}</span>
      <div style="margin-top: 32px;">
        <p-messages [value]="msgs"></p-messages>
        <div class="ui-g-12">
          <label>{{ 'logInPage.email' | translate }}</label>
          <input
            type="text"
            class="ui-inputtext ui-widget"
            pInputText
            [(ngModel)]="loginForm.email"
            name="email"
            #emailInput="ngModel"
            required
            email
          />
          <div
            *ngIf="!emailInput.valid && emailInput.dirty && emailInput.touched"
            class="alert alert-danger"
          >
            {{ 'logInPage.emailError' | translate }}
          </div>
        </div>
        <div class="ui-g-12">
          <label>{{ 'logInPage.password' | translate }}</label>
          <input
            type="password"
            autocomplete="off"
            class="ui-inputtext ui-widget"
            feedback="false"
            [(ngModel)]="loginForm.password"
            name="password"
            #passwordInput="ngModel"
            required
          />
          <div
            [hidden]="passwordInput.valid || passwordInput.pristine"
            class="alert alert-danger"
          >
            {{ 'logInPage.passwordError' | translate }}
          </div>
        </div>
      </div>
      <div class="ui-g-6">
        <button
          type="submit"
          class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only blue-btn ui-button-raised"
          [disabled]="!formLogin.valid"
        >
          {{ 'logInPage.logInBtn' | translate }}
        </button>
      </div>
    </form>
    <div class="right-part">
      <img
        src="assets/layout/images/template/image-login.png"
        alt="harmony-layout"
      />
    </div>
  </div>
</div>
